import React from 'react'
import PostItem from 'components/PostItem'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import ImagePng from 'components/ImagePng'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

const Component = ({
  content,
  hasBg,
  isInside = false,
  showButtonLinks,
  showViewAll,
  category,
  titleContent,
}) => {
  const { locale } = useLocalization()
  return (
    <div
      className={`component-post-list ${hasBg && 'has-bg'} ${
        showButtonLinks && 'show-button-links'
      }`}
    >
      <div className='inner'>
        <Inview className='title-content fade-in-up'>
          {titleContent?.title && <h2>{titleContent.title}</h2>}
          {titleContent?.excerpt && <p>{titleContent.excerpt}</p>}
          {titleContent?.addLogo && (
            <div className='logo'>
              <ImagePng filename='logo_feldenkrais_inverted.png' alt='logo' />
            </div>
          )}
        </Inview>
        {content.map((item, i) => (
          <PostItem
            key={i}
            content={item}
            isInside={isInside}
            category={category}
          />
        ))}
        {showViewAll && <ViewAll locale={locale} />}
      </div>
    </div>
  )
}

export default Component

const ViewAll = ({ locale }) => {
  return (
    <Inview className='button-wrap fade-in-up'>
      <ButtonLink
        content={{
          to: '/training',
          text: `${tr('VIEW_ALL', locale)} »`,
          addClass: 'bg-primary-light',
        }}
      />
    </Inview>
  )
}
