import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const ContentfulRichTextPost = ({ content }) => {
  const options = {
    renderNode: {
      'heading-1': (node, children) => {
        return <PostContentH1>{children}</PostContentH1>
      },
      'heading-2': (node, children) => {
        return <PostContentH2>{children}</PostContentH2>
      },
      'heading-3': (node, children) => {
        return <PostContentH3>{children}</PostContentH3>
      },
      'heading-4': (node, children) => {
        return <PostContentH4>{children}</PostContentH4>
      },
      'heading-5': (node, children) => {
        return <PostContentH5>{children}</PostContentH5>
      },
      'heading-6': (node, children) => {
        return <PostContentH6>{children}</PostContentH6>
      },
      paragraph: (node, children) => {
        return <PostContentP>{children}</PostContentP>
      },
      'unordered-list': (node, children) => {
        return <PostContentUL>{children}</PostContentUL>
      },
      'ordered-list': (node, children) => {
        return <PostContentOL>{children}</PostContentOL>
      },
      blockquote: (node, children) => {
        return <PostContentBlockQuote>{children}</PostContentBlockQuote>
      },
      'embedded-asset-block': (node) => {
        const image = node.data.target
        if (!image) {
          return null
        }
        return <PostContentImage image={image} />
      },
    },
  }
  return <div className='main'>{renderRichText(content, options)}</div>
}

const PostContentH1 = ({ children }) => {
  return (
    <Inview className='h1 fade-in-up'>
      <h1>{children}</h1>
    </Inview>
  )
}

const PostContentH2 = ({ children }) => {
  return (
    <Inview className='h2 fade-in-up'>
      <h2>{children}</h2>
    </Inview>
  )
}

const PostContentH3 = ({ children }) => {
  return (
    <Inview className='h3 fade-in-up'>
      <h3>{children}</h3>
    </Inview>
  )
}

const PostContentH4 = ({ children }) => {
  return (
    <Inview className='h4 fade-in-up'>
      <h4>{children}</h4>
    </Inview>
  )
}

const PostContentH5 = ({ children }) => {
  return (
    <Inview className='h5 fade-in-up'>
      <h5>{children}</h5>
    </Inview>
  )
}

const PostContentH6 = ({ children }) => {
  return (
    <Inview className='h6 fade-in-up h6'>
      <h6>{children}</h6>
    </Inview>
  )
}

const PostContentP = ({ children }) => {
  return (
    <Inview className='p fade-in-up'>
      <p>{children}</p>
    </Inview>
  )
}

const PostContentUL = ({ children }) => {
  return (
    <Inview className='ul fade-in-up'>
      <ul>{children}</ul>
    </Inview>
  )
}

const PostContentOL = ({ children }) => {
  return (
    <Inview className='ol fade-in-up'>
      <ol>{children}</ol>
    </Inview>
  )
}

const PostContentBlockQuote = ({ children }) => {
  return (
    <Inview className='quote fade-in-up'>
      <blockquote>{children}</blockquote>
    </Inview>
  )
}

const PostContentImage = ({ image }) => {
  return (
    <Inview className='image fade-in-up'>
      <ImageWrap image={image} />
    </Inview>
  )
}

export default ContentfulRichTextPost
