import { graphql } from 'gatsby'
import React from 'react'
import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import Post from 'components/Post'
import PostList from 'components/PostList'
import Footer from 'components/Footer'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'
import SEO from 'components/SEO'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  let page = data[`page_${locale}`]
  const resource = data[`resource_${locale}`]
  const resources = [resource] // list of 1

  const categoryTitle = {
    en: {
      teacher: 'Teachers',
      video: 'Videos',
      article: 'Articles',
    },
    zh: {
      teacher: '老师',
      video: 'Videos',
      article: 'Articles',
    },
  }
  const category = resource.resourceType[0].toLowerCase()
  const thisCategoryTitle = categoryTitle[locale][category]

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
      link: `/resources`,
    },
    {
      text: thisCategoryTitle,
      link: `/resources`,
    },
    {
      text: resource.title,
    },
  ]

  page.seo.title = thisCategoryTitle

  return (
    <div className='page'>
      <div>
        <SEO page={page} addTitle={resource.title} />
        <Banner content={page.banner} />
        <Breadcrumb items={breadcrumb} />
        <PostList
          content={resources}
          category={category}
          hasBg={true}
          isInside={true}
        />
        <Post content={resource} category='category' />
        <Footer />
      </div>
    </div>
  )
}

export default Page

export const pageQuery = graphql`
  query ContentfulResourceBySlug($slug: String!) {
    page_en: contentfulPage(
      slug: { eq: "resources" }
      node_locale: { eq: "en-US" }
    ) {
      id
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    page_zh: contentfulPage(
      slug: { eq: "resources" }
      node_locale: { eq: "zh-CN" }
    ) {
      id
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    resource_en: contentfulResource(
      node_locale: { eq: "en-US" }
      slug: { eq: $slug }
    ) {
      slug
      title
      excerpt
      isFeatured
      imageThumb {
        ...GatsbyImage
      }
      resourceType
      post {
        raw
      }
    }
    resource_zh: contentfulResource(
      node_locale: { eq: "zh-CN" }
      slug: { eq: $slug }
    ) {
      slug
      title
      excerpt
      isFeatured
      imageThumb {
        ...GatsbyImage
      }
      resourceType
      post {
        raw
      }
    }
  }
`
