import React from 'react'
import ArrowLink from 'components/ArrowLink'
import ContentfulRichTextPost from 'components/ContentfulRichTextPost'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

const Component = ({ content, category }) => {
  const { locale } = useLocalization()

  const backLinks = {
    training: {
      to: '/training',
      text: tr('BACK_TO_ALL_TRAININGS', locale),
    },
    resources: {
      to: '/resources',
      text: tr('BACK_TO_ALL_RESOURCES', locale),
    },
  }
  const thisBackLink =
    category === 'training' ? backLinks.training : backLinks.resources

  return (
    <div className='component-post'>
      <div className='inner'>
        <ArrowLink content={thisBackLink} isBack={true} />
        <ContentfulRichTextPost content={content.post} />
        <ArrowLink content={thisBackLink} isBack={true} />
      </div>
    </div>
  )
}

export default Component
