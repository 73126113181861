import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import ButtonLink from 'components/ButtonLink'
// import Swoosh from 'components/Swoosh'

const Component = ({ content, isInside, category }) => {
  const linkPaths = {
    teacher: '/resources/teachers/',
    video: '/resources/videos/',
    article: '/resources/articles/',
    training: '/training/',
  }
  const thisLinkPath = linkPaths[category]

  const isSquare = category === 'teacher'

  return (
    <Inview
      className={`component-post-item 
        ${content.isFeatured ? 'is-featured' : ''}
        fade-in-up
      `}
    >
      <div className={`bg ${isInside ? 'is-inside' : ''} `} />
      <div className={`inner ${isSquare ? 'is-square' : ''} `}>
        <div className='image'>
          <div className='thumb'>
            <ImageWrap image={content.imageThumb} />
          </div>
          {/* <Swoosh width='200%' left='-50%' top='10%' opacity='0.1' /> */}
        </div>
        <div className='main'>
          {content.dates && <h4 className='date'>{content.dates}</h4>}
          <div className='text'>
            <h5 className='title'>{content.title}</h5>
            <p className='excerpt'>{content.excerpt}</p>
          </div>
          {content.externalLink && (
            <ButtonLink
              content={{ to: content.externalLink, isExternal: true }}
            />
          )}
          {!content.externalLink && (
            <ButtonLink content={{ to: `${thisLinkPath}${content.slug}` }} />
          )}
        </div>
      </div>
    </Inview>
  )
}

export default Component
